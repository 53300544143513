/* Page Fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400i,700i,900i');
@import url('https://fonts.googleapis.com/css?family=Sofia');
@import url('https://fonts.googleapis.com/css?family=Trirong');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Sacramento');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100;200;300;400;500;600;700;800&display=swap');

body, h5, h6, p {
  margin: 0;
  font-family: 'Quicksand', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4
{
  font-family: 'Playfair Display', serif !important;
}
